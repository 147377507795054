import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import SurroundSound from "@material-ui/icons/SurroundSound";
import SpeakerGroup from "@material-ui/icons/SpeakerGroup";
import Headset from "@material-ui/icons/Headset";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class IntroSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Why would you want that ?</h2>
            <h5 className={classes.description}>
              Immersive Audio, also known as New Generation Audio goes way beyond stereo as any sound source can be placed in a 3D space.The beauty of it lies in the fact that the playback device will use the mix information and <strong>adapt it to any listening environment</strong>.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Plays on usual speakers"
                description="Unlike old multi-channel surround techniques, immersive audio — Dolby Atmos in particular — seamlessly translates to traditional stereo."
                icon={SpeakerGroup}
                iconColor="primary"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Plays on headphones"
                description="When a compatible device sees that headphones are connected, it renders a binaural version of the mix recreating the 3D space with advanced psychoacoustic techniques."
                icon={Headset}
                iconColor="primary"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Plays on smart speakers"
                description="From simple compact speakers or soundbars to hig-end home theater setups, an Atmos mix will translate more and more accurately as the number of speakers increases. The main pathway to a full immersive experience."
                icon={SurroundSound}
                iconColor="primary"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(IntroSection);
